export const EditRequestFormDefaultValue = (data) => {
  
  return {
    title: data?.title,
    included_file: data?.included_file,
    included_email: data?.included_email,
    fields: data?.fields
  };
};

export default EditRequestFormDefaultValue;
