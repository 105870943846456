export const columnsMyRequestsClient = (onClickEdit, onClickRemove, onClickEmployeeList, onClickView) => [
  {
    accessorKey: 'title',
    header: 'Request Type',
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.title}</p>,
  },
  {
    accessorKey: 'Name',
    header: 'Request Name',
    // hishem avelacnem
    size: 130,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: 'employees',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 140,
    Cell: ({ row }) => (
      <p
        className="whitespace-nowrap cursor-pointer"
        onClick={() => onClickEmployeeList(row?.original?.['employees']?.map(({ name }) => name))}>
        {row?.original?.['employees']?.map(
          ({ name }, index, array) => `${name + `${index !== array?.length - 1 ? `,` : ' '} `}`,
        )}
      </p>
    ),
  },
  {
    accessorKey: 'file',
    header: 'Report Available For Download',
    size: 180, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2">
        {row?.original?.['status'] === 'Approved' &&
          row?.original?.['employees']?.map(({ files }, index, array) =>
            files?.length > 0
              ? files?.map(({ url }) =>
                  url ? (
                    <a className="cursor-pointer" href={url} download target="_blank" rel="noreferrer">
                      {`Download` + `${index !== array?.length - 1 ? `,` : ' '} `}
                    </a>
                  ) : (
                    `${index === 0 ? ' - ' : ''} `
                  ),
                )
              : `${index === 0 ? ' - ' : ''} `,
          )}
      </div>
    ),
  },
  {
    accessorKey: 'provider company',
    header: 'Provider Company',
    // hishem avelacnem
    size: 100,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.provider_name}</p>,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    // hishem avelacnem
    size: 90,
    Cell: ({ row }) => (
      <p className="whitespace-nowrap">
        {row?.original?.status === 'Approved'
          ? 'Completed'
          : row?.original?.status === 'Completed'
          ? 'In Process'
          : row?.original?.status}
      </p>
    ),
  },
  {
    accessorKey: 'action',
    header: 'Action',
    size: 80, //decrease the width of this column
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 cursor-pointer">
        <img
          onClick={() =>
            onClickEdit(row?.original?.id, row?.original?.status == 'Open' || row?.original?.status == 'In Process' || row?.original?.status == 'Completed' )
          }
          className="cursor-pointer w-[24px] h-[24px]"
          src={row?.original?.status == 'Open' || row?.original?.status == 'In Process' || row?.original?.status == 'Completed' ? '/EditIcon.png' : '/EditIconDisable.png'}
          alt="EditIcon"
        />
        <img
          src={row?.original?.status == 'Open' ? '/RemoveSVG.svg' : '/RemoveSVGDissabled.svg'}
          alt="Remove"
          onClick={() => onClickRemove(row?.original?.['id'], row?.original?.status == 'Open')}
          className="cursor-pointer"
        />
        <img
          src={'/ViewIcon.svg'}
          alt="ViewIcon"
          onClick={() => onClickView(row?.original?.['id'])}
          className="cursor-pointer"
        />
      </div>
    ),
  },
];
