import { $authHost } from '..';

export default class myRequestsEmployeeHttp {
  static employeeRequestsLinks(id) {
    return $authHost.get(`/employee/get_forms?page=${id ?? ''}`);
  }
  static employeeRequestFormFromClientLinks(id) {
    return $authHost.get(`/employee/get_forms/${id}`);
  }
}
