import { useEffect, useState } from 'react';
import InputLabel from '../../../../../components/InputLabel/InputLabel';
import Select from '../../../../../components/Select/Select';
import ClientHttp from '../../../../../http/clientsHttp/clientsHttp';
import CurrencyInputNew from '../../../../../components/CurrencyInputNew/CurrencyInputNew';
import { useFieldArray } from 'react-hook-form';

function EmployementsInnerFields({
  today,
  control,
  clientsData,
  register,
  setValue,
  errors,
  employmentsIndex,
  getValues,
  salary,
}) {
  const [currentSchoolId, setCurrentSchoolId] = useState(clientsData?.[0]?.id);
  const [servicesData, setServiesData] = useState([]);
  const [positionsData, setPositionsData] = useState([]);
  const [clientsDataServiceCurrent, setClientsDataServiceCurrent] = useState();

  const allClientsSelectData = clientsData?.map(item => ({
    name: item?.['name'],
    id: item?.id,
  }));

  const onChangeClientsData = e => {
    setCurrentSchoolId(e.target.value);
    setValue(`employments.${employmentsIndex}.school_id`, e.target.value);
    setClientsDataServiceCurrent('');
    getValues().employments?.find(
      (elem, index) =>
        elem?.school_id == e.target.value &&
        setValue(`employments.${employmentsIndex}.eligibleRush`, elem?.eligibleRush ?? false),
    );
    getValues().employments?.find(
      (elem, index) =>
        elem?.school_id == e.target.value &&
        setValue(`employments.${employmentsIndex}.eligibleOvertime`, elem?.eligibleOvertime ?? false),
    );
  };

  const {
    fields: ContactPhonesFields,
    append: ContactPhonesAppend,
    remove: ContactPhonesRemove,
  } = useFieldArray({
    control,
    name: `employments[${employmentsIndex}].currency`,
    // contacts[${index}].contact_phones
  });

  const onClickAddNewNumber = () => {
    ContactPhonesAppend({});
  };

  useEffect(() => {
    currentSchoolId &&
      ClientHttp.servicesLinks(currentSchoolId).then(e => {
        const uniqueIdsSet = new Set();
        const serivceList = e?.data?.data
          ?.map(item => ({
            id: item?.id,
            name: item?.name,
          }))
          .reduce((result, item) => {
            if (!uniqueIdsSet.has(item.id)) {
              uniqueIdsSet.add(item.id);
              result.push(item);
            }
            return result;
          }, []);
        setServiesData(serivceList);
        // setPositionsData(e?.data?.data?.positions)
        // setClientsDataServiceCurrent(e?.data?.data?.[0]?.id) hishenq nayenq
      });
  }, [currentSchoolId]);

  useEffect(() => {
    clientsDataServiceCurrent && currentSchoolId
      ? ClientHttp.positionsLinksForEmpoloyMent(currentSchoolId, clientsDataServiceCurrent).then(e => {
          setPositionsData(e?.data?.data);
        })
      : setPositionsData([]);
  }, [clientsDataServiceCurrent, currentSchoolId]);

  useEffect(() => {
    setValue(`employments.${employmentsIndex}.service_id`, '');
  }, [servicesData]);

  useEffect(() => {
    setValue(`employments.${employmentsIndex}.position_id`, '');
  }, [positionsData]);

  return (
    <div className="grid md:grid-cols-2 sm:gap-6 gap-2 space-y-5">
      <div className="w-full bg-white mt-5">
        <Select
          register={register}
          title={'Client Company'}
          selectData={allClientsSelectData}
          selectClass="flex-col gap-2"
          name={`employments.${employmentsIndex}.school_id`}
          onChange={onChangeClientsData}
          className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
          classNameTitle="text-[#60657B] opacity-50 text-xs"
        />
        {errors && (
          <p className="mb-0 text-sm text-amber-800">{errors?.employments?.[employmentsIndex]?.school_id?.message}</p>
        )}
      </div>
      <div className="w-full bg-white">
        <Select
          register={register}
          title={'Services'}
          selectData={servicesData}
          selectClass="flex-col gap-2"
          name={`employments.${employmentsIndex}.service_id`}
          onChange={e => setClientsDataServiceCurrent(e.target.value)}
          className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
          classNameTitle="text-[#60657B] opacity-50 text-xs"
        />
        {errors && (
          <p className="mb-0 text-sm text-amber-800">{errors?.employments?.[employmentsIndex]?.service_id?.message}</p>
        )}
      </div>
      <div className="mt-5">
        <Select
          register={register}
          title={'Position'}
          selectData={positionsData}
          selectClass="flex-col z-50 gap-2"
          name={`employments.${employmentsIndex}.position_id`}
          className="w-full box-content focus:outline-none h-[38px] border rounded cursor-pointer"
          classNameTitle="text-[#60657B] opacity-50 text-xs px-1"
        />
        {errors && (
          <p className="mb-0 text-sm text-amber-800">{errors?.employments?.[employmentsIndex]?.position_id?.message}</p>
        )}
      </div>
      <div>
        <Select
          register={register}
          title={'Type'}
          selectData={[
            { id: 1, name: 'Full Time' },
            { id: 2, name: 'Part Time' },
            { id: 3, name: 'Contract' },
          ]}
          selectClass="flex-col gap-2"
          name={`employments.${employmentsIndex}.type`}
          className="w-full box-content focus:outline-none h-[38px] border rounded cursor-pointer"
          classNameTitle="text-[#60657B] opacity-50 text-xs"
        />
        {errors && (
          <p className="mb-0 text-sm text-amber-800">{errors?.employments?.[employmentsIndex]?.type?.message}</p>
        )}
      </div>
      <div id="currencyUseFieldArray">
        {ContactPhonesFields.map((item, phoneindex) => {
          return (
            <div key={item.id} className="">
              <div className="grid md:grid-cols-2 md:gap-6 gap-2">
                <div className="">
                  <CurrencyInputNew
                    classNameLabel="text-[#60657B]  text-xs font-normal p-1 opacity-50"
                    className={'w-full border p-[7px] outline-none rounded border p-1'}
                    name={`employments.${employmentsIndex}.currency.${phoneindex}.hourly_rate`}
                    setValue={setValue}
                    disabled={salary === 0}
                    placeholder="$0.00"
                    labelTitle={'Hourly Rate'}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.employments?.[employmentsIndex]?.currency?.[phoneindex]?.hourly_rate?.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="flex">
                    <InputLabel
                      classNameInput="flex flex-col w-full"
                      classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                      className="w-full border p-[7px]"
                      type="date"
                      disabled={salary === 0}
                      labelTitle={'Start Date'}
                      max={today}
                      register={register}
                      name={`employments.${employmentsIndex}.currency.${phoneindex}.start_date`}
                    />

                    {phoneindex >= 1 && (
                      <button
                        type="button"
                        className="w-8 h-[40px] mt-6 box-border rounded border border-[#E4E6F2] text-[#60657B]"
                        onClick={() => ContactPhonesRemove(phoneindex)}>
                        X
                      </button>
                    )}
                  </div>
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.employments?.[employmentsIndex]?.currency?.[phoneindex]?.start_date?.message}
                    </p>
                  )}
                </div>
              </div>
              {phoneindex === 0 && (
                <section>
                  <button
                    className="text-[#2B2D38] opacity-50 text-base"
                    type="button"
                    onClick={onClickAddNewNumber}
                    disabled={salary === 0}>
                    + Add New Hourly Rate
                  </button>
                </section>
              )}
            </div>
          );
        })}
      </div>
      {/* --------------------- */}
      <div className="mt-5">
        <InputLabel
          classNameInput="flex flex-col"
          classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
          className="w-full border p-[7px]"
          labelTitle={'Assignment Details'}
          placeholder={'Details'}
          register={register}
          name={`employments.${employmentsIndex}.assignment_details`}
        />
        {errors && (
          <p className="mb-0 text-sm text-amber-800">
            {errors?.employments?.[employmentsIndex]?.assignment_details?.message}
          </p>
        )}
      </div>
      <div className="relative flex items-center">
        <>
          <div className="flex h-6 items-center">
            <input
              id={`employments.${employmentsIndex}.status`}
              aria-describedby="comments-description"
              name={`status`}
              {...register(`employments.${employmentsIndex}.status`)}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor={`employments.${employmentsIndex}.status`} className="font-medium text-gray-900">
              Is Active Employment
            </label>
          </div>
        </>
        <>
          <div className="flex h-6 items-center ml-5">
            <input
              id={`employments.${employmentsIndex}.eligibleRush`}
              aria-describedby="comments-description"
              // onClick={() => setclick((prev) => !prev)}
              onClick={e =>
                getValues()?.employments?.forEach(
                  (item, index) =>
                    item?.school_id == currentSchoolId &&
                    setValue(`employments.${index}.eligibleRush`, e?.target?.checked),
                )
              }
              // onChange={()=>}
              // name={`rush2`}
              // value={1}
              {...register(`employments.${employmentsIndex}.eligibleRush`)}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor={`employments.${employmentsIndex}.eligibleRush`} className="font-medium text-gray-900">
              Eligible for Rush Rates
            </label>
          </div>
        </>
        <>
          <div className="flex h-6 items-center ml-5">
            <input
              id={`employments.${employmentsIndex}.eligibleOvertime`}
              aria-describedby="comments-description"
              // onClick={() => setclick((prev) => !prev)}
              onClick={e =>
                getValues()?.employments?.forEach(
                  (item, index) =>
                    item?.school_id == currentSchoolId &&
                    setValue(`employments.${index}.eligibleOvertime`, e?.target?.checked),
                )
              }
              // onChange={()=>}
              // name={`rush2`}
              // value={1}
              {...register(`employments.${employmentsIndex}.eligibleOvertime`)}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor={`employments.${employmentsIndex}.eligibleOvertime`} className="font-medium text-gray-900">
              Eligible for Over Time
            </label>
          </div>
        </>
      </div>
    </div>
  );
}

export default EmployementsInnerFields;
