import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import Title from '../../components/Title/Title';
import InputLabel from '../../components/InputLabel/InputLabel';
import CheckboxesFields from './components/CheckboxesFields';
import ViewFormClientDefaultValue from './ViewFormClientDefaultValue';

import requestHttp from '../../http/requestHttp/requestHttp';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../components/Button/Button';
import { useDropzone } from 'react-dropzone';
import MultiDropZone from '../../components/DropZone/MultiDropZone';

export default function ViewFormClient() {
  const [form, setForm] = useState([]);
  const [showFile, setShowFile] = useState(false);
  const [stateImage, setStateImage] = useState('');
  const [showAddEmail, setShowAddEmail] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone();

  const params = useParams();
  const navigate = useNavigate();
  const OnClickCancle = () => {
    navigate('/Client/MyRequests');
  };
  const {
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => ViewFormClientDefaultValue(form), [form]),
  });

  const { fields: Fields } = useFieldArray({
    control,
    name: 'formFields',
  });

  useEffect(() => {
    reset(ViewFormClientDefaultValue(form));
    setShowFile(!!form?.included_file);
    setShowAddEmail(!!form?.included_email);
    if (!!form?.files?.length > 0) {
      setStateImage({ cdnUrl: form?.files });
    }else {
      setStateImage([]);
    }
  }, [form]);

  useEffect(() => {
    requestHttp.getSubmittedFormClient(params?.id).then(e => {
      setForm(e?.data?.data);
    });
  }, []);

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`Edit Form for - ${form?.title}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form className="w-full">
          <div className="py-4 w-full">
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline">
              {Fields?.map(({ label, type, id }, index) =>
                type === 'input' ? (
                  <div className="w-full" key={id}>
                    <InputLabel
                      labelTitle={label}
                      register={register}
                      name={`formFields.${index}.value`}
                      classNameLabel="text-[#60657B] opacity-50 text-xs"
                      classNameInput="flex flex-col flex-1"
                      type={'text'}
                      placeholder={label}
                      className="w-full"
                      required={{ required: 'This field is required' }}
                      disabled={true}
                    />
                    {errors && (
                      <p className="mb-0 text-sm text-amber-800">{errors?.formFields?.[index]?.value?.message}</p>
                    )}
                  </div>
                ) : (
                  type === 'date' && (
                    <div className="" key={id + label}>
                      <InputLabel
                        classNameInput="flex flex-col "
                        classNameLabel={'font-[system-ui] text-[#60657B] text-xs font-normal opacity-50'}
                        className="w-full border p-[7px]"
                        type="date"
                        labelTitle={label}
                        register={register}
                        name={`formFields.${index}.value`}
                        required={{ required: 'This field is required' }}
                        disabled={true}
                      />
                      {errors && (
                        <p className="mb-0 text-sm text-amber-800">{errors?.formFields?.[index]?.value?.message}</p>
                      )}
                    </div>
                  )
                ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              {Fields?.map(
                ({ name, label, type, id }, index) =>
                  type === 'textarea' && (
                    <div className="" key={id + label}>
                      <div className="w-full flex flex-col gap-2">
                        <label className={'font-[system-ui] text-[#60657B] opacity-50 text-xs font-normal'}>
                          {label}
                        </label>
                        <textarea
                          rows={7}
                          disabled={true}
                          className="p-[14px] outline-none w-full border"
                          {...register(`formFields.${index}.value`, { required: true })}
                        />
                        {errors && (
                          <p className="mb-0 text-sm text-amber-800">{errors?.formFields?.[index]?.value?.message}</p>
                        )}
                      </div>
                    </div>
                  ),
              )}
            </div>
            <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline mt-5 h-full">
              {Fields?.map(
                ({ name, label, type, id }, index) =>
                  type === 'checkbox' && (
                    <div className="space-y-5 h-full" key={id + name + label}>
                      <p className="text-lg font-semibold">{label}</p>
                      <CheckboxesFields control={control} register={register} index={index} disabled={true} />
                    </div>
                  ),
              )}
            </div>
            {showAddEmail && (
              <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2 items-baseline">
                <div className="w-full mt-5 ">
                  <InputLabel
                    labelTitle={'Applicant Email'}
                    inputId={`email`}
                    className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                    placeholder={`Notification Recipient's Email`}
                    classNameInput="w-full"
                    name={`email`}
                    register={register}
                    classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
                    required={{ required: 'This field is required' }}
                    disabled={true}
                  />
                  {errors && <p className="mb-0 text-sm text-amber-800">{errors?.email?.message}</p>}
                </div>
              </div>
            )}
            {showFile && (
              <div className="sm:px-7 px-4">
                <MultiDropZone
                  getRootProps={getRootProps}
                  getInputProps={false}
                  isFocused={isFocused}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                  state={stateImage}
                />
              </div>
            )}
          </div>
          <div>
            <div className="flex gap-2 justify-end border-t py-2 sm:pr-8 sm:pr-2 pr-4 mt-2">
              <Button
                buttonText={'Cancel'}
                onClick={OnClickCancle}
                className="bg-inherit border text-[#000] box-border px-3"
                type="button"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
