import { Fragment, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import Select from '../Select/Select';

const people = [{}];

export default function SearchUseFieldArray({
  setValue,
  clientsData,
  servicesIndex,
  editData,
  employee,
  register,
  errors,
  create,
  today,
  getValues,
}) {


  
  const [position, setPosition] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(getValues()?.services?.[servicesIndex]?.position_id);
  // const [disabled, setDisabled] = useState(false);
  const [query, setQuery] = useState('');
  const [state1, setState1] = useState([]);
  const [selected, setSelected] = useState(getValues()?.services?.[servicesIndex]);
  const [currentServiceId, setCurrentServiceId] = useState();
  // const dispatch = useDispatch();
  const filteredPeople =
    query === ''
      ? [{}]
      : people.filter(person =>
          person.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  const displayvalue = person => {
    // if (employee) {
    //   setValue(`${employee}.${servicesIndex}.service_id`, person?.service_id || state1?.[0]?.id);
    //   create && dispatch(SET_POSITIONS(person?.positions));
    //   !create && setPosition(state1?.[0]?.positions || person?.position);
    //   create && setPosition(state1?.positions || person?.positions);
    //   setValue(`${employee}.${servicesIndex}.position_id`, person?.position_id);
    // } else {
      setValue(`services.${servicesIndex}.id`, person?.id);
      setValue(`services.${servicesIndex}.code`, person?.code);
      setCurrentServiceId(person?.id);
    // }
    return person?.name;
  };

  // const disable = getValues().services?.map((elem) => elem.id)

  const debounceSearchByInn = async event => {
    const query = event.target.value;
    if (query) setState1([]);
    const response = await ClientHttp.serviceSearch(query).then(data => data?.data?.data);
    setState1(response);
  };

  useEffect(() => {
    currentServiceId
      ? ClientHttp.positionsLinks(currentServiceId).then(e => {
          setPosition(e?.data?.data);
          // setValue(`services.${servicesIndex}.position_id`, "")
        })
      : setPosition([]);
  }, [currentServiceId]);

  useEffect(() => {
    setValue(`services.${servicesIndex}.position_id`, currentPosition);
  }, [position]);

  return (
    <div>
      {!employee && (
        <div className="grid md:grid-cols-2 sm:gap-6 gap-2">
          <div className="z-10 ">
            <p className={'text-[#60657B] opacity-50 text-xs font-normal mb-2'}>Service Name</p>
            <Combobox value={selected} onChange={setSelected}>
              <div className="relative">
                <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none  sm:text-sm">
                  <Combobox.Input
                    className="w-full border-none outline-0 z-10 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                    displayValue={displayvalue}
                    placeholder="Service Name"
                    onChange={debounceSearchByInn}
                    disabled={getValues()?.services?.[servicesIndex]?.disableField}
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setQuery('')}>
                  <Combobox.Options className="absolute  z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredPeople.length === 0 && query !== '' ? (
                      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
                    ) : (
                      state1?.map(person => (
                        <Combobox.Option
                          key={person.id}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-teal-600 text-white' : 'text-gray-900 z-40'
                            }`
                          }
                          value={person}
                          // disabled={disable.includes(person.id)}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                {person.name}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? 'text-white' : 'text-teal-600'
                                  }`}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
              {errors && (
                <p className="mb-0 text-sm text-amber-800">{errors?.services?.[servicesIndex]?.id?.message}</p>
              )}
            </Combobox>
          </div>
          {position && (
            <div className="w-full mt-0">
              <p className={'text-[#60657B] opacity-50 text-xs font-normal mb-2'}>Position</p>
              <Select
                register={register}
                selectData={position}
                selectClass="flex-col gap-2"
                disable={getValues()?.services?.[servicesIndex]?.disableField}
                name={`services.${servicesIndex}.position_id`}
                className="w-full box-content focus:outline-none h-[34px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.position_id?.message}</p>}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
