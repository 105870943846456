import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import PaymentsHttp from '../../http/PaymentsHttp/PaymentsHttp';
import Pagination from '../../components/Pagination/Pagination';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import TableClients from '../../components/DataTable/TableClients';
import PartialPaidModal from '../../components/Modal/PartialPaidModal';
import ModalYesOrNo from '../../components/Modal/ModalYesOrNo';
import { afterAPICall, columnsPaimantAdmin } from './helpers/PaymentUtils';

import 'react-toastify/dist/ReactToastify.css';
import TitleBlockPayments from '../../components/TitleBlocks/TitleBlockPayments';

export default function Payments() {
  const [dataId, setDateId] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [statusCurrent, setStatusCurrent] = useState('');
  const [clientCurrent, setClientCurrent] = useState('');
  const [data, setData] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [isSend, setIsSend] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dataPartial, setDataPartial] = useState();
  const [companyFormId, setCompanyFormId] = useState();

  const onClickUpdate = (id, status, page) => {
    PaymentsHttp.updatePayment(id, status)
      .then(e => afterAPICall('Status Successfully Changed'))
      .then(() => setIsOpenModal(false))
      .then(() => setCurrentPage(page))
      .then(() => setIsSend(prev => !prev));
  };

  const onClickOpenPartial = (id, page) => {
    setIsOpen(true);
    setDataPartial({ id: id, page: page });
  };

  const onClickPartial = (id, data, page) => {
    PaymentsHttp.parital(id, data)
      .then(e => afterAPICall('Status Successfully Changed'))
      .then(() => setCurrentPage(page))
      .then(() => setIsSend(prev => !prev));
    setIsOpen(false);
  };

  const onClickRemind = (id, page) => {
    PaymentsHttp.remindPayment(id)
      .then(e => afterAPICall('Message Successfully Sent'))
      .then(() => setCurrentPage(page));
  };

  const onPaginationClick = id => {
    PaymentsHttp.getInvoiceStatuses().then(e => {
      e?.data?.statuses.unshift({ name: 'All Statuses', id: '' });
      const statuses = e?.data?.statuses;
      setStatuses(statuses);
    });

    ClientHttp.getAllClientss().then(e => {
      e?.data?.data.unshift({ name: 'All Clients', id: '' });
      const clients = e?.data?.data;
      setClientData(clients);
    });

    PaymentsHttp.getPaymentsByIdAndDate(dataId, dateRange, statusCurrent, clientCurrent, id).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
  };

  const onClickYes = (id, page) => onClickUpdate(id, { payment_status: 0 }, page);

  const onClickOpen = (id, page) => {
    setIsOpenModal(true);
    setCompanyFormId({ id: id, page: page });
  };

  useEffect(() => {
    PaymentsHttp.getInvoiceStatuses().then(e => {
      e?.data?.statuses.unshift({ name: 'All Statuses', id: '' });
      const statuses = e?.data?.statuses;
      setStatuses(statuses);
    });

    ClientHttp.getAllClientss().then(e => {
      e?.data?.data.unshift({ name: 'All Clients', id: '' });
      const clients = e?.data?.data;
      setClientData(clients);
    });

    PaymentsHttp.getPaymentsByIdAndDate(dataId, dateRange, statusCurrent, clientCurrent, currentPage).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
    
  }, [dataId, isSend, dateRange, statusCurrent, clientCurrent]);

  return (
    <div className="w-full sm:p-8 p-2 overflow-hidden">
      <ModalYesOrNo
        open={isOpenModal}
        setOpen={setIsOpenModal}
        onClickYes={onClickYes}
        companyFormId={companyFormId}
        text={'Are you sure you want to make Open this Invoice ?'}
      />
      <PartialPaidModal open={isOpen} setOpen={setIsOpen} onClickPartial={onClickPartial} dataPartial={dataPartial} />
      <TitleBlockPayments
        setDateRange={setDateRange}
        setDateId={setDateId}
        textItem="Paiements"
        className="sm:text-base text-xs	 font-semibold"
        setStatusCurrent={setStatusCurrent}
        statuses={statuses}
        setClientCurrent={setClientCurrent}
        clientData={clientData}
        src={'/InvoicesIcon.svg'}
        setCurrentPage={setCurrentPage}
      />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-[1000px]">
            <TableClients
              tbody={data ?? []}
              columns={columnsPaimantAdmin(onClickUpdate, dataPage, onClickOpenPartial, onClickOpen, onClickRemind)}
              maxHeight="500px"
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage.links} />
      </div>
      <ToastContainer />
    </div>
  );
}
