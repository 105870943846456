import { useFieldArray, useForm } from 'react-hook-form';
import Select from '../../components/Select/Select';
import Title from '../../components/Title/Title';
import { useEffect, useMemo, useState } from 'react';
import InputLabel from '../../components/InputLabel/InputLabel';
import DatePickerTime from '../../components/DatePickerTime/DatePickerTime';
import EditServiceLogRequestDefaultValue from './EditServiceLogRequestDefaultValue';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import serviceLogHttp from '../../http/serviceLogHttp/serviceLogHttp';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import TimerDifference from '../../components/TimerDifference/TimerDifference';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestErrorEditServiceLogRequest from './RequestErrorEditServiceLogRequest';
import TotalTime from '../AddServiceLogRequest/TotalTime';
import CreateableSelect from '../../components/CreateableSelect/CreateableSelect';
import { useSelector } from 'react-redux';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';

import 'react-toastify/dist/ReactToastify.css';

export default function EditServiceLogRequest() {
  const errorTime = useSelector(state => state?.error?.error);
  const navigate = useNavigate();
  const params = useParams();

  const [errorss, setErrorss] = useState(false);
  const [diableAdd, setDiableAdd] = useState(false);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [clientsDataServiceCurrent, setClientsDataServiceCurrent] = useState();
  // const [clientsDataCurrent, setClientsDataCurrent] = useState();
  const [requestsDataCurrent, setRequestsDataCurrent] = useState();
  const [positionsData, setPositionsData] = useState([]);
  const [errorWhenAprrove, setErrorWhenAprrove] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [requestsData, setRequestsData] = useState([]);
  const [activitiesData, setActivitiesData] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: useMemo(() => EditServiceLogRequestDefaultValue(data), [data]),
    resolver: yupResolver(useMemo(() => RequestErrorEditServiceLogRequest(), [])),
  });

  const isEligibleOvertimeForRequests =
    useSelector(state => state?.adminProfile?.employeeProfiledata?.eligibleOvertimeForRequests) == 1 ? true : false;

  const {
    fields: ServiceLogTimeActivitiesFields,
    append: ServiceLogTimeActivitiesAppend,
    remove: ServiceLogTimeActivitiesRemove,
  } = useFieldArray({
    control,
    name: 'serviceLogTimeActivities',
  });

  const onChangeRequestsData = e => {
    getValues('serviceLogTimeActivities').forEach((elem, index) => {
      setValue(`serviceLogTimeActivities.${index}.position_id`, '');
      setValue(`serviceLogTimeActivities.${index}.activiteee`, {});
    });
    setRequestsDataCurrent(e.target.value);
    setValue('request_id', e.target.value);
    const currentRequest = requestsData?.find(elem => elem?.id == e.target.value);
    setValue('service', currentRequest?.service);
    setValue('service_id', currentRequest?.service_id);
    setValue('school', currentRequest?.school);
    setValue('school_id', currentRequest?.school_id);
    setClientsDataServiceCurrent(currentRequest?.service_id);
    // setPositionsData([]);
  };

  const afterSubmit = () => {
    notifySuccess('Request Log successfully changed');
    setTimeout(() => {
      navigate(`/Employee/ServiceLog/Calendar/${state?.id1}`);
    }, 2000);
  };

  const afterDelete = () => {
    // setIsLoading(true);
    notifySuccess('Request Log successfully Deleted');
    setTimeout(() => {
      navigate(`/Employee/ServiceLog/Calendar/${state?.id1}`);
    }, 2000);
  };

  const onError = err => {
    (err || err?.response?.data?.message || err?.response?.data?.errors) && setIsLoading(false);
    err?.response?.status == '403'
      ? setErrorWhenAprrove('You can not change Approved Service Log')
      : setErrorWhenAprrove(err?.response?.data?.message || err?.response?.data?.errors);
  };

  const onSubmit = data => {
    // setIsLoading(true);
    data.serviceLogTimeActivities = data.serviceLogTimeActivities.map(elem => ({
      id: elem?.id,
      position_id: elem?.position_id,
      activity_id: elem?.activiteee?.value !== elem?.activiteee?.label ? elem?.activiteee?.value : null,
      activity: elem?.activiteee?.value == elem?.activiteee?.label ? elem?.activiteee?.value : null,
      start_time:
        typeof elem?.start_time === 'string' && !elem?.start_time?.includes('GMT')
          ? elem?.start_time
          : elem?.start_time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      end_time:
        typeof elem?.end_time === 'string'
          ? elem?.end_time
          : elem?.end_time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
    }));
    setIsLoading(true);
    serviceLogHttp
      .updateserviceLogRequestLinks(params?.id, data)
      .then(() => afterSubmit())
      // .catch(err =>
      //   // console.log(err),
      //   // (err?.response?.data?.message || err?.response?.data?.errors) && setIsLoading(false)
      //   err?.response?.status == '403'
      //     ? setErrorWhenAprrove('You can not change Approved Service Log')
      //     : setErrorWhenAprrove(err?.response?.data?.message || err?.response?.data?.errors),
      // );
      .catch(onError);
  };

  const onClickAddNewTimeActivity = lastTime => {
    const Startdate = new Date(lastTime);
    Startdate.setMinutes(Startdate.getMinutes());
    Startdate.setHours(Startdate.getHours());
    Startdate.setSeconds('00');
    const Enddate = new Date(lastTime);
    Enddate.setMinutes(Startdate.getMinutes() == '45' ? '00' : Enddate.getMinutes() + 15);
    Enddate.setHours(Startdate.getMinutes() == '45' ? Enddate.getHours() + 1 : Enddate.getHours());
    Enddate.setSeconds('00');
    ServiceLogTimeActivitiesAppend({
      start_time: Startdate,
      end_time: Enddate,
    });
  };

  const onClickCancle = () => navigate(`/Employee/ServiceLog/Calendar/${state?.id1}`);

  const onClickDelete = () => {
    setIsOpen(true);
  };

  const onClickYes = id => {
    serviceLogHttp.serviceLogDeleteRequestLinks(id).then(() => afterDelete());
    setIsOpen(false);
  };

  useEffect(() => {
    serviceLogHttp.currentserviceLogRequestLinks(params?.id).then(e => {
      setData(e?.data?.data);
    });
  }, []);

  useEffect(() => {
    serviceLogHttp.serviceLogRequestsLinks().then(e => {
      setRequestsData(e?.data?.data);
      setClientsDataServiceCurrent(data?.service_id);
      // setClientsDataCurrent(data?.school_id);
    });
    // setServiceTypeCurrent(data?.service_type_id);
  }, [data]);

  useEffect(() => {
    clientsDataServiceCurrent &&
      serviceLogHttp.serviceLogPositionsRequestLinks(clientsDataServiceCurrent).then(e => {
        setPositionsData(e?.data?.data);
      });
    clientsDataServiceCurrent &&
      serviceLogHttp.serviceLogActivitiesRequestLinks(clientsDataServiceCurrent).then(e => {
        setActivitiesData(e?.data?.data);
      });
  }, [clientsDataServiceCurrent]);

  useEffect(() => {
    reset(EditServiceLogRequestDefaultValue(data));
  }, [data, requestsData]);

  useEffect(() => {
    requestsDataCurrent &&
      getValues('serviceLogTimeActivities').forEach((elem, index) => {
        setValue(`serviceLogTimeActivities.${index}.position_id`, '');
        setValue(`serviceLogTimeActivities.${index}.activiteee`, {});
      });
  }, [requestsDataCurrent]);

  const more4Hour = watch('serviceLogTimeActivities')?.some(e => e?.currentMinError === true);
  const min15Step = watch('serviceLogTimeActivities')?.some(e => e?.currentStepMinError === true);
  const activitesCount = watch('serviceLogTimeActivities');

  useEffect(() => {
    activitesCount?.length == 1 && setDiableAdd(false);
    activitesCount?.length == 1 && setErrorss(false);
  }, [activitesCount]);

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <CompanyFormModal
        open={isOpen}
        setOpen={setIsOpen}
        onClickYes={onClickYes}
        companyFormId={params?.id}
        text="Are you sure you want to delete this Request Log"
      />
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Edit Request Log'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2">
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Request Name'}
                selectData={requestsData}
                selectClass="flex-col gap-2"
                onChange={onChangeRequestsData}
                name={'request_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.request_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <InputLabel
                labelTitle={'Client Company'}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Client Company'}
                register={register}
                disabled={true}
                name={`school`}
                classNameLabel={'text-[#60657B] text-xs font-normal opacity-50'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <InputLabel
                labelTitle={'Service'}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Service'}
                register={register}
                disabled={true}
                name={`service`}
                classNameLabel={'text-[#60657B] text-xs font-normal opacity-50'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.service_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <InputLabel
                classNameInput="flex flex-col"
                classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                className="w-full border p-[7px]"
                type="date"
                max={new Date().toISOString().split('T')[0]}
                labelTitle={'Date'}
                register={register}
                name={'date'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.date?.message}</p>}
            </div>
          </div>
          {ServiceLogTimeActivitiesFields.map((elem, index) => (
            <div key={elem?.id} className="grid sm:px-7 px-4 grid-cols-1 gap-2 mt-5">
              <div className="flex flex-row gap-2 md:w-1/2 md:pr-2">
                <div>
                  <DatePickerTime
                    setError={setError}
                    title="Start Time"
                    setValue={setValue}
                    value={getValues(`serviceLogTimeActivities.${index}.start_time`)}
                    name={`serviceLogTimeActivities.${index}.start_time`}
                    control={control}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.start_time?.message}
                    </p>
                  )}
                </div>
                <div>
                  <DatePickerTime
                    setError={setError}
                    title="End Time"
                    setValue={setValue}
                    name={`serviceLogTimeActivities.${index}.end_time`}
                    control={control}
                    value={getValues(`serviceLogTimeActivities.${index}.end_time`)}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.end_time?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-4">
                <div className="w-full bg-white mt-2">
                  <Select
                    register={register}
                    title={'Position'}
                    selectData={positionsData}
                    selectClass="flex-col gap-2"
                    name={`serviceLogTimeActivities.${index}.position_id`}
                    className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                    classNameTitle="text-[#60657B] opacity-50 text-xs"
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.position_id?.message}
                    </p>
                  )}
                </div>
                <div className="mt-2">
                  <CreateableSelect
                    register={register}
                    getValues={getValues}
                    defaultOption={activitiesData}
                    control={control}
                    setValue={setValue}
                    name={`serviceLogTimeActivities.${index}.activiteee`}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.activiteee?.value?.message ||
                        errors?.serviceLogTimeActivities?.[index]?.activiteee?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-5">
                <TimerDifference
                  setErrorss={setErrorss}
                  setValue={setValue}
                  index={index}
                  nameStart={`serviceLogTimeActivities.${index}.start_time`}
                  nameEnd={`serviceLogTimeActivities.${index}.end_time`}
                  control={control}
                />
              </div>
              <>
                {index !== 0 && index == ServiceLogTimeActivitiesFields.length - 1 && (
                  <TotalTime
                    control={control}
                    index={index}
                    setErrorss={setErrorss}
                    setDiableAdd={setDiableAdd}
                    isEligibleOvertimeForRequests={isEligibleOvertimeForRequests}
                  />
                )}
              </>
              <div className="flex justify-end">
                <section
                  className={`border rounded  text-center px-3 py-1 min-w-[204px] max-w-[204px] cursor-pointer ${
                    (diableAdd || errorss || more4Hour) && `bg-[#A6ADC9]`
                  } `}
                  onClick={() =>
                    onClickAddNewTimeActivity(
                      getValues(
                        `serviceLogTimeActivities.${getValues(`serviceLogTimeActivities`)?.length - 1}.end_time`,
                      ),
                    )
                  }>
                  <button
                    className={`text-[#4885ED] text-sm font-normal ${
                      (diableAdd || errorss || more4Hour) && `text-[white]`
                    }`}
                    type="button"
                    disabled={diableAdd || errorss || more4Hour}>
                    Add New Time Activity
                  </button>
                </section>
              </div>
              {ServiceLogTimeActivitiesFields.length > 1 && (
                <div className="flex flex-col gap-6">
                  <div className="flex justify-end">
                    <section
                      className="border rounded px-3 py-1  text-center min-w-[204px] max-w-[204px] cursor-pointer"
                      onClick={() => {
                        ServiceLogTimeActivitiesRemove(index);
                      }}>
                      <button className="text-[#4885ED] text-sm font-normal" type="button">
                        Remove Time Activity
                      </button>
                    </section>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="sm:px-7 px-4 mt-5">
            {errorWhenAprrove && <p className="mb-0 text-sm text-amber-800">{errorWhenAprrove}</p>}
          </div>
          <div className="mt-6">
            <CancleAdd
              buttonText={'Save'}
              onClickCancle={onClickCancle}
              disabled={isLoading || errorss || errorTime || more4Hour || min15Step}
              isDelete={true}
              onClickDelete={onClickDelete}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
