export const AssignRequestToEmployeeDefaultValue = data => {
  return {
    // employee_ids:data?.employees,
    // deadline: data?.deadline,
    // service_id: data?.service_id,
    employee_ids:
      data?.employee_ids?.length > 0
        ? data?.employee_ids
        : [
            {
              service_id: '',
              id: '',
            },
          ],
  };
};

export default AssignRequestToEmployeeDefaultValue;
