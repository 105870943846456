export const EditFormClientDefaultValue = form => {
  return {
    email:form?.email,
    formFields: form?.formFields?.map(({ id, name, label, type, value, options_selected }) => ({
      id: id,
      name: name,
      label: label,
      type: type,
      value: value,
      options:
        type === 'checkbox'
          ? options_selected?.map(elem => ({
              name: elem?.name,
              [elem?.name]: elem?.[elem?.name] === "false" ? false : elem?.[elem?.name],
              // a:true
            }))
          : null,
    })),
  };
};

export default EditFormClientDefaultValue;
