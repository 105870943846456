import { $authHost, $authHostt, $authHosttUpload } from '..';

export default class requestHttp {
  static getRequestLogsPaginationList(
    id,
    dataEmployeeCurrent,
    page,
    dataClientCurrent,
    dataPublishCurrent,
    dataSentCurrent,
  ) {
    return $authHost.get(
      `/admin/requestLogs?id=${id ? id : ''}&employee_id=${dataEmployeeCurrent ?? ''}&school_id=${
        dataClientCurrent ?? ''
      }&page=${page ?? ''}&publish=${dataPublishCurrent ?? ''}&send=${dataSentCurrent ?? ''}`,
    );
  }
  static getRequestPdf(id) {
    return $authHostt.get(`/admin/requestLogs/${id}`);
  }
  static getRequestPdfClient(id) {
    return $authHostt.get(`/client/requestLogs/${id}`);
  }
  static getRequestLogsClient(dataEmployeeCurrent, id) {
    return $authHost.get(`/client/requestLogs?employee_id=${dataEmployeeCurrent ?? ''}&page=${id}`);
  }
  static requestLogView(request_id, employee_id) {
    return $authHost.get(`/admin/viewAllLogs/request/${request_id}/employee/${employee_id}`);
  }
  static getRequestLogsForPublish(school_id, group_school_id, publish) {
    return $authHost.get(
      `/admin/requestLogsForSend?school_id=${school_id ?? ''}&group_school_id=${
        group_school_id ?? ''
      }&publish=${publish}`,
    );
  }
  static getRequestLogsForSend(school_id, group_school_id) {
    return $authHost.get(
      `/admin/requestLogsForSend?school_id=${school_id ?? ''}&group_school_id=${group_school_id ?? ''}`,
    );
  }
  static createReqestLogAdmin(data) {
    return $authHost.post(`/admin/createRequestLog`, data);
  }
  static removeFileFormAdmin(id,fileId) {
    return $authHost.post(`/admin/remove_file_form/${id}/${fileId}`,);
  }

  static sendReqestLogsAdmin(data) {
    return $authHost.post(`/admin/sendRequestLogs`, data);
  }
  static publishReqestLogsAdmin(data) {
    return $authHost.post(`/admin/moveRequestLogs`, data);
  }
  static sendRequestLog(id) {
    return $authHost.post(`/admin/sendRequestLog/${id}`);
  }
  static moveRequestLog(id) {
    return $authHost.post(`/admin/moveRequestLog/${id}`);
  }
  static unMoveRequestLog(id) {
    return $authHost.post(`/admin/unMoveRequestLog/${id}`);
  }
  static fillFormForAdmin(id, client_id, data) {
    return $authHosttUpload.post(`/admin/submit/${id}/${client_id}`, data);
  }
  static acceptRequestFormAdmin(id, data) {
    return $authHosttUpload.post(`/admin/reviewForm/${id}`, data);
  }
  static unAcceptRequestFormAdmin(id) {
    return $authHost.post(`/admin/unReviewForm/${id}`);
  }
  static getRequestForms(id) {
    return $authHost.get(`/admin/forms?page=${id ? id : ''}`);
  }
  static getRequestLogsForCreating(id, school_id) {
    return $authHost.get(`/admin/getEmployeesForRequestLogs?group_school_id=${id}&school_id=${school_id}`);
  }
  static getRequestFormsForFillAdmin(id) {
    return $authHost.get(`/admin/getForms/${id}`);
  }
  static getRequestHours(id) {
    return $authHost.get(`/admin/getRequestHours/${id}`);
  }
  static getRequests(id) {
    return $authHost.get(`/admin/submitted_forms?page=${id ? id : ''}`);
  }
  static getRequestForm(id) {
    return $authHost.get(`/admin/forms/${id}`);
  }
  static createRequestForm(data) {
    return $authHost.post(`/admin/forms`, data);
  }
  static editRequestForm(id, data) {
    return $authHost.post(`/admin/forms/${id}`, data);
  }
  static deleteRequestForm(id) {
    return $authHost.post(`/admin/delete_form/${id}`);
  }
  static deleteRequest(id) {
    return $authHost.post(`/admin/delete_submitted_form/${id}`);
  }
  static assignRequestFormToClients(id, data) {
    return $authHost.post(`/admin/assign_school_form/${id}`, data);
  }
  static assignRequestFormToEmployees(id, data) {
    return $authHost.post(`/admin/assign_to_employee/${id}`, data);
  }
  static getSubmittedFormAdmin(id) {
    return $authHost.get(`/admin/submitted_form/${id}`);
  }
  static editSubmittedFormAdmin(id, data) {
    return $authHosttUpload.post(`/admin/update/submitted_form/${id}`, data);
  }
  static updateFormNoChangeStatusAdmin(id, data) {
    return $authHosttUpload.post(`/admin/updateForm/${id}`, data);
  }
  static getSubmittedEmployeesAdmin(id) {
    return $authHost.get(`/admin/submitted_form_with_employee/${id}`);
  }
  // -----------------------------------------------
  static getRequestFormsClient(id) {
    return $authHost.get(`/client/getForms?page=${id ? id : ''}`);
  }
  static getMyRequestsClient(id) {
    return $authHost.get(`/client/getSubmittedForms?page=${id ? id : ''}`);
  }
  static getSubmittedFormClient(id) {
    return $authHost.get(`/client/submitted_form/${id}`);
  }
  static getFormForFillClient(id) {
    return $authHost.get(`/client/getForms/${id}`);
  }
  static fillFormForClient(id, data) {
    return $authHosttUpload.post(`/client/submit/${id}`, data);
  }
  static editFormForClient(id, data) {
    return $authHosttUpload.post(`/client/submit_form/${id}`, data);
  }
  static deleteRequestForClient(id) {
    return $authHost.post(`/client/submit_form_delete/${id}`);
  }
  static deleteFileForClientForm(id,fileId) {
    return $authHost.post(`/client/remove_file_form/${id}/${fileId}`);
  }
  //-----------------------------------------------------------
  static completeRequestForEmployee(id) {
    return $authHost.post(`/employee/complete/${id}`);
  }
  static unCompleteRequestForEmployee(id) {
    return $authHost.post(`/employee/unComplete/${id}`);
  }
  static getSubmittedFormEmployee(id) {
    return $authHost.get(`/employee/get_forms/${id}`);
  }
  static addFile(id, data) {
    return $authHosttUpload.post(`/employee/add_file/${id}`, data);
  }
  static removeFileForEmployee(id, file_id) {
    return $authHost.post(`/employee/remove_file/${id}/${file_id}`);
  }
}
