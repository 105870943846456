import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import Pagination from '../../components/Pagination/Pagination';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import InfoListModal from '../../compo../../components/Modal/InfoListModal';
import TableClients from '../../components/DataTable/TableClients';
import { columnsRequest } from './helpers/RequestsUtils';
import requestHttp from '../../http/requestHttp/requestHttp';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Button from '../../components/Button/Button';

export default function Request() {
  const navigate = useNavigate();
  const [requestList, setRequestList] = useState([]);
  const [requestListPage, setRequestListDataPage] = useState();
  const [newLoad, setNewLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [companyFormId, setCompanyFormId] = useState();
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [List, setList] = useState();

  useEffect(() => {
    requestHttp.getRequests('').then(e => {
      setRequestListDataPage(e?.data);
      setRequestList(e?.data?.data);
    });
  }, [newLoad]);

  const onPaginationClick = id => {
    requestHttp.getRequests(id).then(e => {
      setRequestListDataPage(e?.data);
      setRequestList(e?.data?.data);
    });
  };

  const onClickRemove = id => {
    setIsOpen(true);
    setCompanyFormId(id);
  };

  const onClickYes = id => {
    requestHttp.deleteRequest(id).then(e => e.status === 200 && setNewLoad(!newLoad));
    setIsOpen(false);
  };

  const onClickEmployeeList = list => {
    if (list?.array?.length > 1) {
      setIsOpenEmployee(true);
      setList(list);
    }
  };
  const onClickAssignEmployees = (id, title, employees) => {
    navigate(`/Admin/AssignRequestToEmployees/${id}`, { state: { title: title, employees: employees } });
  };

  const onClickEdit = id => {
    navigate(`/Admin/EditForm/${id}`);
  };
  const onClickAccept = id => {
    navigate(`/Admin/AcceptRequestForm/${id}`);
  };

  const afterSubmit = () => {
    notifySuccess('Request status successfully Changed');
  };

  const onClickUnAccept = id => {
    requestHttp
      .unAcceptRequestFormAdmin(id)
      .then(e => e.status === 200 && afterSubmit())
      .then(() => setNewLoad(!newLoad));
  };

  return (
    <div className="w-full sm:p-8 p-2">
      <CompanyFormModal
        open={isOpen}
        setOpen={setIsOpen}
        onClickYes={onClickYes}
        companyFormId={companyFormId}
        text="Are you sure you want to delete this Request ?"
      />
      <InfoListModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        List={List}
        title={'For which employees is this Request'}
      />
      <TitleBlok src={'/CompanyFormsIcon.svg'} textItem={'Requests'} className="text-base font-bold">
        <div className="flex sm:gap-4 gap-2">
          <Button
            onClick={() => navigate('/Admin/AssignFormToClient')}
            buttonText={'Create Request'}
            className={'text-[#fff] sm:text-sm text-xs'}
          />
        </div>
      </TitleBlok>
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-full">
            <TableClients
              maxHeight="500px"
              tbody={requestList ?? []}
              columns={columnsRequest(
                onClickEmployeeList,
                onClickEdit,
                onClickRemove,
                onClickAssignEmployees,
                onClickAccept,
                onClickUnAccept,
              )}
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={requestListPage?.links} />
      </div>
      <ToastContainer />
    </div>
  );
}
