import { useMemo } from 'react';

export default function DropZone({
  getRootProps,
  getInputProps,
  isFocused,
  isDragAccept,
  isDragReject,
  state,
  text="Click to select files"
}) {
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const focusedStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div className=" flex gap-4 mt-4 items-center">
      {state?.cdnUrl &&
      <div>
        {['png', 'jpeg', 'jpg', 'swg', 'webp', 'svg']?.some(ext => state?.cdnUrl?.endsWith(ext)) && (
           <button type="button">
           <a
             href={state?.cdnUrl}
             download={state?.filename}
             target="_blank"
             className="w-full h-full"
             rel="noreferrer">
             <div className="flex items-center">
             <img alt="img" src={state?.cdnUrl} className="w-20 h-20" />
               <p> {state?.cdnUrl.split('/').pop()}</p>
             </div>
           </a>
         </button>
          //
        )}
        {state?.cdnUrl?.endsWith('pdf') && (
          <button type="button">
            <a
              href={state?.cdnUrl}
              download={state?.filename}
              target="_blank"
              className="w-full h-full"
              rel="noreferrer">
              <div className="flex items-center">
                <img alt="swg" src={'/PDFIcon.png'} className="w-16 h-16" />
                <p> {state?.cdnUrl.split('/').pop()}</p>
              </div>
            </a>
          </button>
        )}
        {['csv', 'xlsx','xls']?.some(ext => state?.cdnUrl?.endsWith(ext)) && (
                  <button type="button">
                    <a
                      href={state?.cdnUrl}
                      download={state?.filename}
                      target="_blank"
                      className="w-full h-full"
                      rel="noreferrer">
                      <div className="flex items-center">
                        <img alt="swg" src={'/ExcelPdf.svg'} className="w-16 h-16" />
                        <p> {state?.cdnUrl.split('/').pop()}</p>
                      </div>
                    </a>
                  </button>
                )}
                {['docx','doc']?.some(ext => state?.cdnUrl?.endsWith(ext)) && (
                  <button type="button">
                    <a
                      href={state?.cdnUrl}
                      download={state?.filename}
                      target="_blank"
                      className="w-full h-full"
                      rel="noreferrer">
                      <div className="flex items-center">
                        <img alt="swg" src={'/WordPdf.svg'} className="w-16 h-16" />
                        <p> {state?.cdnUrl.split('/').pop()}</p>
                      </div>
                    </a>
                  </button>
                )}
      </div>}
      {getInputProps &&
       <section className="container">
        <div {...getRootProps({ style })}>
          <input
            {...getInputProps()}
            aria-describedby="file_input_help"
            id="file_input"
            type="file"
            style={{ display: 'block' }}
          />
          <p>{text}</p>
        </div>
      </section>}
    </div>
  );
}

