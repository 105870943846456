import { useEffect } from 'react';
import InputLabel from '../../../components/InputLabel/InputLabel';
import Select from '../../../components/Select/Select';

export default function InnerFields({
  item,
  allEmployeesData,
  index,
  data,
  setValue,
  errors,
  register,
  services,
  Remove,
  onClickAppend,
}) {

  useEffect(() => {
    setValue(`employee_ids.${index}.service_id`, data?.employee_ids?.[index]?.service_id);
  }, [services]);

  useEffect(() => {
    setValue(`employee_ids.${index}.id`, data?.employee_ids?.[index]?.id);
  }, [allEmployeesData]);

  return (
    <>
      <div className="w-full bg-white p-4 rounded-md" key={item?.id}>
        <div className="grid  items-end">
          <div className="w-full bg-white">
            <Select
              register={register}
              title={'To Employee'}
              selectData={allEmployeesData}
              selectClass="flex-col gap-2"
              name={`employee_ids.${index}.id`}
              className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
              classNameTitle="text-[#60657B] opacity-50 text-xs"
            />
            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.employee_ids?.[index]?.id?.message}</p>}
          </div>
        </div>
        <div className="mt-5">
          <InputLabel
            classNameInput="flex flex-col"
            classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
            className="w-full border p-[7px]"
            type="date"
            labelTitle={'Completion deadline'}
            // max={today}
            register={register}
            name={`employee_ids.${index}.deadline`}
          />
          {errors && <p className="mb-0 text-sm text-amber-800">{errors?.employee_ids?.[index]?.deadline?.message}</p>}
        </div>
        <div className="w-full bg-white mt-5">
          <Select
            register={register}
            title={'Service'}
            selectData={services ?? []}
            selectClass="flex-col gap-2"
            name={`employee_ids.${index}.service_id`}
            className="w-full pl-4 focus:outline-none h-[40px] border rounded cursor-pointer"
            classNameTitle="text-[#60657B] opacity-50 text-xs"
            required={{ required: 'This field is required' }}
          />
          {errors && (
            <p className="mb-0 text-sm text-amber-800">{errors?.employee_ids?.[index]?.service_id?.message}</p>
          )}
        </div>
        {index !== 0 && (
          <div className="grid mt-5 grid-cols-1 sm:gap-6 gap-2">
            <section className="text-end ">
              <button
                onClick={() => {
                  Remove(index);
                }}
                className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
                type="button">
                Remove 
              </button>
            </section>
          </div>
        )}
        <div className="grid grid-cols-1 sm:gap-6 gap-2 mt-5">
          <section className="text-end">
            <button
              className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
              type="button"
              onClick={onClickAppend}>
              Add
            </button>
          </section>
        </div>
      </div>
    </>
  );
}
