import { useEffect, useMemo, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { notifySuccess } from '../../utils/utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import RequestErrorAssignRequestFormToClients from './RequestErrorAssignRequestFormToClients';
import companyFormsHttp from '../../http/companyFormsHttp/companyFormsHttp';
import requestHttp from '../../http/requestHttp/requestHttp';
import AssignRequestToClientsDefaultValue from './AssignRequestToClientsDefaultValue';
import ClientHttp from '../../http/clientsHttp/clientsHttp';

export default function AssignRequestFormToClients() {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const { state } = useLocation();

  const isEmpty = state?.clients?.length == 0

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = useForm({
    defaultValues: useMemo(() => AssignRequestToClientsDefaultValue(state), [state]),
    resolver: yupResolver(useMemo(() => RequestErrorAssignRequestFormToClients(isEmpty), [isEmpty])),
  });

  const navigate = useNavigate();
  const [allClientsData, setAllClientsData] = useState([]);

  useEffect(() => {
    ClientHttp.getAllClientss().then(e => {
      const uniqueIdsSet = new Set();
      const clientsList = e?.data?.data
        ?.map(item => ({
          value: item?.id || item?.[0]?.employee_id,
          label: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.value)) {
            uniqueIdsSet.add(item.value);
            result.push(item);
          }

          return result;
        }, []);
      clientsList.unshift({ value: 'All', label: 'All Clients' });
      setAllClientsData(clientsList);
    });
    reset(AssignRequestToClientsDefaultValue(state))
  }, []);

  const afterSubmit = () => {
    notifySuccess('Request Form successfully Assigned');
    setTimeout(() => {
      navigate('/Admin/RequestForms');
    }, 2000);
  };

  const onSubmit = async data => {
    setIsLoading(true);
    data.school_ids = data?.school_ids || []
    requestHttp
      ?.assignRequestFormToClients(params?.id, data)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false);
      });
  };

  const OnClickCancle = () => {
    navigate('/Admin/RequestForms');
  };



  return (
    <div className="sm:px-8 px-2 py-8 w-full ">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`${state?.clients?.length>0 ? "Edit Assigned" : "Assign"} clients to ${state?.title}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full bg-white p-4 rounded-md">
            <div className="grid  items-end">
              <div className="w-full bg-white">
                <MultiSelect
                  title={'To Clients'}
                  selectData={allClientsData}
                  selectClass="flex-col gap-2"
                  name={'school_ids'}
                  All="All Clients"
                  defalutValue={
                    state?.clients?.length > 0
                      ? state?.clients?.map(elem => {
                          return { value: elem?.id, label: elem?.name };
                        })
                      : []
                  }
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer h-full"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  setValue={setValue}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_ids?.message}</p>}
              </div>
            </div>
          </div>
          <div className="mt-40 z-50">
            <CancleAdd disabled={isLoading} buttonText={`Assign`} onClickCancle={OnClickCancle} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
