import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../../components/InputLabel/InputLabel';
import InputLabel from '../../../components/InputLabel/InputLabel';
import Title from '../../../components/Title/Title';
import PhoneInput from '../../../components/PhoneInput/PhoneInput';
import Select from '../../../components/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { loadCitiesSlice, loadStatesSlice, loadStatusesSlice } from '../../../redux/clientsSlice/clientsSlice';
import CancleAdd from '../../../components/CancleAdd/CancleAdd';
import { useNavigate, useParams } from 'react-router-dom';
import EmployeesHttp from '../../../http/employeesHttp/employeesHttp';
import { SocialNumber } from '../../../components/SocialNumber/SocialNumber';
import { EditEmployeeDefaultValue } from './EditEmployeeDefaultValue';
import RequestErrorAddNewEmployee from './RequestErrorEditEmployee';
import { ToastContainer } from 'react-toastify';
import { notifySuccess, onlyLetters, onlyNumbers } from '../../../utils/utils';
import ContractsEmployeeField from './componets/ContractsEmployeeField/ContractsEmployeeField';
import EmploymentsField from './componets/EmploymentsField/EmploymentsField';
import { Widget } from '@uploadcare/react-widget';
import CreateableSelect from '../../../components/CreateableSelect/CreateableSelect';
import CurrencyInputNew from '../../../components/CurrencyInputNew/CurrencyInputNew';

import 'react-toastify/dist/ReactToastify.css';
import { useDropzone } from 'react-dropzone';
import DropZone from '../../../components/DropZone/DropZone';

export default function EditEmployee() {
  const [isLoading, setIsLoading] = useState(false);

  const statesData = useSelector(state => state?.clients?.statesSliceData?.data);
  const citiesData = useSelector(state => state?.clients?.statesCitiesData?.data);
  const statusesData = useSelector(state => state?.clients?.storeStatusesData?.statuses);
  const [employeeData, setEmployeeData] = useState([]);
  const [resetAgain, setResetAgain] = useState(false);
  const [state, setState] = useState('');
  const [stateSingature, setStateSingature] = useState('');
  const [employmentType, setEmploymentType] = useState([]);
  const [is_both, setIs_both] = useState(false);
  const [salary, setSalary] = useState(employeeData?.employee_salary_type);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    setError,
    watch,
    getValues,
  } = useForm({
    defaultValues: useMemo(() => EditEmployeeDefaultValue(employeeData), [employeeData]),
    resolver: yupResolver(useMemo(() => RequestErrorAddNewEmployee(is_both, salary), [is_both, salary])),
  });

  const params = useParams();
  const id = params?.id;

  const afterSubmit = () => {
    notifySuccess('Employee successfully changed');
    setTimeout(() => {
      navigate('/Admin/Employees');
    }, 2000);
  };
  const onSubmit = data => {
    setIsLoading(true);
    const sendData = {
      id: data?.id,
      name: data?.name,
      weeks: data?.weeks,
      hours: data?.hours,
      lastName: data?.lastName,
      middleName: data?.middleName,
      mileageReimbursement: data?.mileageReimbursement ? 1 : 0,
      eligibleOvertimeForRequests: data?.eligibleOvertimeForRequests ? 1 : 0,
      companyBenefitsPlan: data?.companyBenefitsPlan ? 1 : 0,
      email: data?.email,
      verified: data?.verified,
      email_personal: data?.email_personal,
      cell_phone: data?.cell_phone,
      alt_phone: data?.alt_phone,
      dob: data?.dob,
      socialSecurityNumber: data?.socialSecurityNumber.replace(/-/g, ''),
      driverLicense: data?.driverLicense,
      status: data?.status,
      gender: data?.gender,
      state_id: data?.state_id,
      employee_type: 0,
      city_id: data?.city_id,
      street: data?.street,
      is_both: data?.is_both ? 1 : 0,
      zipcode: data?.zipcode,
      employee_salary_type: data?.employee_salary_type,
      assignment_details: data?.assignment_details || null,
      position_id: data?.positions?.value !== data?.positions?.label ? data?.positions?.value : null,
      position: data?.positions?.value === data?.positions?.label ? data?.positions?.value : null,
      type: data?.type || null,
      start_calculating_logs_date: data?.start_calculating_logs_date,
      contacts: data?.contacts?.map((elem, index) => ({
        id: elem?.id,
        ext: elem?.ext,
        employee_id: elem?.employee_id,
        state_id: elem?.state_id,
        city_id: elem?.city_id,
        firstName: elem?.firstName,
        middleName: elem?.middleName,
        lastName: elem?.lastName,
        zipCode: elem?.zipCode,
        street: elem?.street,
        phone: elem?.phone,
        created_at: elem?.created_at,
        updated_at: elem?.updated_at,
      })),
      employments: data?.employments?.map((item, empIndex) => ({
        id: item?.id,
        employee_id: item?.employee_id,
        school_id: item?.school_id,
        service_id: item?.service_id,
        position_id: item?.position_id,
        currency:
          salary == 1 || salary == 2
            ? item?.currency?.map((currencyItem, currencyIndex) => ({
                id: currencyItem?.id,
                start_date: currencyItem?.start_date,
                hourly_rate: currencyItem?.hourly_rate.replace(/,/g, '.').replace(/\$/g, ''),
              }))
            : [],
        // start_date: item?.start_date,
        // hourly_rate: item?.hourly_rate.replace(/,/g, '').replace(/\$/g, ''),
        type: item?.type,
        assignment_details: item?.assignment_details,
        created_at: item?.created_at,
        updated_at: item?.updated_at,
        status: item?.status ? 1 : 0,
        eligibleRush: item?.eligibleRush ? 1 : 0,
        eligibleOvertime: item?.eligibleOvertime ? 1 : 0,
      })),
      weekGenerations: data?.weekGenerations?.map(weekItem => ({
        id: weekItem?.id || 0,
        day: weekItem?.day,
      })),
    };
    sendData.base_salaries =
      salary == 0 || salary == 2
        ? data?.base_salaries?.map(item => ({
            id: item?.id,
            start_date: item?.start_date,
            monthly_salary: item?.hourly_rate.replace(/,/g, '').replace(/\$/g, ''),
          }))
        : [];

    const formData = new FormData();
    formData.append('file', state?.sourceInfo?.file ?? '');
    sendData.employee_image = formData.get('file');
    formData.append('fileSign', acceptedFiles?.[0] ?? '');
    sendData.employee_signature = formData.get('fileSign') || data?.file;
    EmployeesHttp.editEmployeeData(id, sendData)
      .then(() => {
        afterSubmit();
      })
      .catch(err => {
        if (err?.response?.data?.status == 400) {
          setResetAgain(!resetAgain);
          notifySuccess(err?.response?.data?.message, true);
          setIsLoading(false);
        } else {
          const errorFromBackend = Object.keys(err?.response?.data?.errors);
          errorFromBackend.forEach(item =>
            setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
          );
          setIsLoading(false);
        }
      });
  };
  const today = new Date().toISOString().split('T')[0];

  const loginFields = [
    {
      labelText: 'First Name ',
      id: 1,
      name: 'name',
      type: 'text',
      placeholder: 'First Name ',
    },
    {
      labelText: 'Middle Name',
      id: 2,
      name: 'middleName',
      type: 'text',
      placeholder: 'Middle Name',
    },
    {
      labelText: 'Last Name',
      id: 3,
      name: 'lastName',
      type: 'text',
      placeholder: 'Last Name',
    },
  ];

  const currentStateValue = useWatch({ control, name: 'state_id' });
  const dispatch = useDispatch();
  useEffect(() => {
    EmployeesHttp.getEmployeeData(params?.id).then(e => {
      setEmployeeData(e?.data?.data);
    });
    dispatch(loadStatesSlice());
    dispatch(loadStatusesSlice());
    EmployeesHttp.employeesEmploymentType().then(e => setEmploymentType(e?.data?.data));
    currentStateValue && dispatch(loadCitiesSlice(currentStateValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StateChange = e => {
    dispatch(loadCitiesSlice(e.target.value));
    setValue('city_id', '');
  };

  const stateCityCurrentID = useWatch({ control, name: `city_id` });

  const {
    fields: ContractsEmployeeFields,
    append: ContractsEmployeeAppend,
    remove: ContractsEmployeeRemove,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const {
    fields: CurrencyFields,
    append: CurrencyAppend,
    remove: CurrencyRemove,
  } = useFieldArray({
    control,
    name: `base_salaries`,
  });
  const {
    fields: WeekGenerationsFields,
    append: WeekGenerationsAppend,
    remove: WeekGenerationsRemove,
  } = useFieldArray({
    control,
    name: `weekGenerations`,
  });

  const onClickAddNewNumber = () => {
    CurrencyAppend({});
  };
  const onClickAddWeek = () => {
    WeekGenerationsAppend({});
  };

  const {
    fields: EmploymentsFields,
    append: EmploymentsAppend,
    remove: EmploymentsRemove,
  } = useFieldArray({
    control,
    name: 'employments',
  });

  const widgetApi = useRef(null);
  const navigate = useNavigate();

  const onClickAddEmergencyContact = () => ContractsEmployeeAppend({ state_id: statesData?.[0].id });
  // const onClickResendVerifycationCode = () => EmployeesHttp.resendCode(id).then(() => {
  //   afterResendCode()
  // });

  const onClickCancle = () => navigate('/Admin/Employees');

  useEffect(() => {
    reset(EditEmployeeDefaultValue(employeeData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData, resetAgain]);

  useEffect(() => {
    setValue('city_id', stateCityCurrentID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citiesData]);

  useEffect(() => {
    currentStateValue && dispatch(loadCitiesSlice(currentStateValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStateValue]);

  const handleChangeImage = fileInfo => {
    setIsLoading(true);
    setState(fileInfo);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (employeeData?.employee_image) {
      setState({ cdnUrl: employeeData?.employee_image });
    }
    if (employeeData?.employee_signature) {
      setStateSingature({ cdnUrl: employeeData?.employee_signature });
    }
    if (employeeData?.employee_salary_type) {
      setSalary(employeeData?.employee_salary_type);
    }
    if (employeeData?.is_both) {
      setIs_both(employeeData?.is_both);
    }
  }, [employeeData]);

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { 'image/*': [] },
  });

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="w-full bg-white p-4 rounded-md">
          <Title
            title={'Edit Employee'}
            className="border-b py-1"
            classNameTitle={'text-2xl text-[#2B2D38] font-semibold'}
          />
          {/* 1 line */}
          <div className=" flex gap-4 mt-4" id="widget_ID">
            <div>{state?.cdnUrl && <img src={state.cdnUrl} alt="uploaded" className="w-28 h-20" />}</div>
            <Widget
              previewStep={false}
              onChange={e => handleChangeImage(e)}
              tabs="file"
              crop
              value={null}
              ref={widgetApi}
              publicKey="d5f2c3d1892201bdfdc1"
            />
          </div>
          <div className="grid md:grid-cols-3 sm:gap-6 gap-2 mt-5">
            {loginFields?.map(e => (
              <div key={e?.id}>
                <Input
                  labelTitle={e?.labelText}
                  inputId={e?.id}
                  className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                  placeholder={e?.placeholder}
                  register={register}
                  onKeyPress={onlyLetters}
                  name={`${e?.name}`}
                  classNameLabel={'text-[#60657B] text-xs font-normal opacity-50'}
                />
                {errors && 'middleName' != e?.name && (
                  <p className="mb-0 text-sm text-amber-800">{errors?.[e?.name]?.message}</p>
                )}
              </div>
            ))}
          </div>
          {/* 2 line */}
          <div className="grid md:grid-cols-2 lg:grid-cols-4 md:gap-6 gap-2 space-y-5">
            <div className="grow mt-5">
              <InputLabel
                classNameInput="flex flex-col"
                classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                className="w-full border p-[7px]"
                type="date"
                labelTitle={'Date of Birth'}
                max={today}
                register={register}
                name={'dob'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.dob?.message}</p>}
            </div>
            <div>
              <PhoneInput
                className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                labelTitle={'Cell phone'}
                name={'cell_phone'}
                control={control}
                classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.cell_phone?.message}</p>}
            </div>
            <div>
              <PhoneInput
                className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                labelTitle={'Alternative phone'}
                name={'alt_phone'}
                control={control}
                classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.alt_phone?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Gender'}
                selectData={[
                  { id: 1, name: 'Male' },
                  { id: 2, name: 'Female' },
                ]}
                selectClass="flex-col gap-2"
                name={'gender'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.gender?.message}</p>}
            </div>
          </div>
          {/* 3 line */}
          <div className="grid md:grid-cols-3 md:gap-6 gap-2 space-y-5">
            <div className="mt-5">
              <Input
                labelTitle={'Street Address'}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Street Address'}
                register={register}
                name={`street`}
                classNameLabel={'text-[#60657B] text-xs font-normal opacity-50'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.street?.message}</p>}
            </div>

            <div className="w-full bg-white">
              <Select
                register={register}
                title={'City'}
                selectData={citiesData}
                selectClass="flex-col gap-2"
                name={'city_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.city_id?.message}</p>}
            </div>
            <div className="w-full bg-white ">
              <Select
                register={register}
                title={'State'}
                selectData={statesData}
                selectClass="flex-col gap-2"
                onChange={e => StateChange(e)}
                name={'state_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.state_id?.message}</p>}
            </div>
          </div>
          {/* 4 line */}
          <div className="grid md:grid-cols-3 md:gap-6 gap-2 space-y-5">
            <div className="mt-5">
              <Input
                labelTitle={'Zip Code'}
                inputId={`zipcode`}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Zip Code'}
                name={`zipcode`}
                register={register}
                classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                onKeyPress={onlyNumbers}
                maxLength={5}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.zipcode?.message}</p>}
            </div>
            <div>
              <SocialNumber
                name={'socialSecurityNumber'}
                control={control}
                textLabel={'Social Security Number'}
                classLabel={'text-[#60657B] opacity-50 text-xs font-normal p-1'}
                className={'focus:outline-none border text-gray-900 text-sm rounded block w-full p-2.5'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.socialSecurityNumber?.message}</p>}
            </div>
            <div>
              <Input
                labelTitle={'Driver’s License #'}
                inputId={`driverLicense`}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'License #'}
                name={`driverLicense`}
                register={register}
                classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.driverLicense?.message}</p>}
            </div>
          </div>
          {/* 5 line */}
          <div className="grid md:grid-cols-3 md:gap-6 gap-2 space-y-5">
            <div className="w-full mt-5">
              <InputLabel
                labelTitle={'Work Email'}
                inputId={`email`}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Work Email'}
                classNameInput="w-full"
                name={`email`}
                register={register}
                classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.email?.message}</p>}
            </div>
            <div className="w-full">
              <InputLabel
                labelTitle={'Personal Email'}
                inputId={`email_personal`}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Personal Email'}
                classNameInput="w-full"
                name={`email_personal`}
                register={register}
                classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.email_personal?.message}</p>}
            </div>
            {statusesData?.length && (
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Employee Status'}
                  selectData={[
                    { name: 'Inactive', id: 0 },
                    { name: 'Active', id: 2 },
                    { name: 'In Process', id: 1 },
                  ]}
                  selectClass="flex-col gap-2"
                  name={'status'}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.status?.message}</p>}
              </div>
            )}
          </div>
          <div className="grid md:grid-cols-3 md:gap-6 gap-2 space-y-5 items-baseline">
            <div className="w-full mt-5">
              <InputLabel
                classNameInput="flex flex-col"
                classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                className="w-full border p-[7px]"
                type="date"
                labelTitle={'Start Calculating Logs Date'}
                max={today}
                register={register}
                name={`start_calculating_logs_date`}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.start_calculating_logs_date?.message}</p>}
            </div>
            <div
              className={`grid ${salary == 0 || salary == 2 ? 'md:grid-cols-2' : 'md:grid-cols-1'}  md:gap-6 gap-2 `}>
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Salary Type'}
                  onChange={e => {
                    setSalary(+e?.target?.value);
                    e?.taget?.value == 0 && setValue('week', 56);
                    e?.taget?.value == 0 && setValue('hours', 8);
                  }}
                  selectData={
                    !!is_both
                      ? [
                          { name: 'Base Salary', id: 0 },
                          { name: 'Hourly wage', id: 1 },
                        ]
                      : [
                          { name: 'Base Salary', id: 0 },
                          { name: 'Hourly wage', id: 1 },
                          { name: 'Base Salary + Hourly', id: 2 },
                        ]
                  }
                  selectClass="flex-col gap-2"
                  name={'employee_salary_type'}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.employee_salary_type?.message}</p>}
              </div>
              {(salary == 0 || salary == 2) && (
                <div className="grid md:grid-cols-2 md:gap-6 gap-2">
                  <div>
                    <InputLabel
                      labelTitle={'Weeks per year'}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Weeks per year'}
                      name={`weeks`}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                      onKeyPress={onlyNumbers}
                      maxLength={2}
                    />
                    {errors && <p className="mb-0 text-sm text-amber-800">{errors?.weeks?.message}</p>}
                  </div>
                  <div>
                    <InputLabel
                      labelTitle={'Hours per day'}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Hours per day'}
                      name={`hours`}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                      onKeyPress={onlyNumbers}
                      maxLength={1}
                    />
                    {errors && <p className="mb-0 text-sm text-amber-800">{errors?.hours?.message}</p>}
                  </div>
                </div>
              )}
            </div>
            <div id="currencyUseFieldArray">
              {CurrencyFields.map((item, phoneindex) => {
                return (
                  <div key={item.id} className="">
                    <div className="grid md:grid-cols-2 md:gap-6 gap-2">
                      <div className="">
                        <CurrencyInputNew
                          classNameLabel="text-[#60657B]  text-xs font-normal p-1 opacity-50 mb-2"
                          className={'w-full border p-[7px] outline-none rounded border p-1'}
                          name={`base_salaries.${phoneindex}.hourly_rate`}
                          setValue={setValue}
                          placeholder="$0.00"
                          labelTitle={'Montly Rate'}
                          disabled={salary == 1}
                          defaultValue={employeeData?.base_salaries?.[phoneindex]?.monthly_salary}
                        />
                        {errors && (
                          <p className="mb-0 text-sm text-amber-800">
                            {errors?.base_salaries?.[phoneindex]?.hourly_rate?.message}
                          </p>
                        )}
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-end">
                          <InputLabel
                            classNameInput="flex flex-col w-full"
                            classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                            className="w-full border p-[7px]"
                            type="date"
                            disabled={salary == 1}
                            labelTitle={'Start Date'}
                            max={today}
                            register={register}
                            name={`base_salaries.${phoneindex}.start_date`}
                          />

                          {phoneindex >= 1 && (
                            <button
                              type="button"
                              className="w-8 h-[40px] mt-6 box-border rounded border border-[#E4E6F2] text-[#60657B]"
                              onClick={() => CurrencyRemove(phoneindex)}>
                              X
                            </button>
                          )}
                        </div>
                        {errors && (
                          <p className="mb-0 text-sm text-amber-800">
                            {errors?.base_salaries?.[phoneindex]?.start_date?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    {phoneindex === 0 && (
                      <section>
                        <button
                          className="text-[#2B2D38] opacity-50 text-base"
                          type="button"
                          disabled={salary == 1}
                          onClick={onClickAddNewNumber}>
                          + Add New Rate
                        </button>
                      </section>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {!!is_both && (
            <div className="grid md:grid-cols-3 md:gap-6 gap-2 space-y-5 items-end">
              <div className="mt-5">
                <CreateableSelect
                  register={register}
                  defaultOption={employmentType ?? []}
                  control={control}
                  setValue={setValue}
                  name={`positions`}
                  title="Position"
                  placeholder="Select or Create Position"
                />
                {errors && (
                  <p className="mb-0 text-sm text-amber-800">
                    {errors?.positions?.value?.message || errors?.positions?.message}
                  </p>
                )}
              </div>
              <div className="w-full ">
                <Select
                  register={register}
                  title={'Type'}
                  selectData={[
                    { id: 1, name: 'Full Time' },
                    { id: 2, name: 'Part Time' },
                    { id: 3, name: 'Contract' },
                  ]}
                  selectClass="flex-col gap-2"
                  name={`type`}
                  className="w-full box-content focus:outline-none h-[38px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.type?.message}</p>}
              </div>
              <div className="">
                <InputLabel
                  classNameInput="flex flex-col"
                  classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                  className="w-full border p-[7px]"
                  labelTitle={'Assignment Details'}
                  placeholder={'Details'}
                  register={register}
                  name={`assignment_details`}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.assignment_details?.message}</p>}
              </div>
            </div>
          )}

          {employeeData?.status == 0 &&
            WeekGenerationsFields?.map((item, weekIndex) => {
              return (
                <div className="grid mt-5 md:grid-cols-3 sm:gap-6 gap-2">
                  <div className="flex flex-col" key={item?.id}>
                    <div className="flex items-end">
                      <InputLabel
                        classNameInput="flex flex-col w-full"
                        classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                        className="w-full border p-[7px]"
                        type="date"
                        labelTitle={'Start Date'}
                        max={today}
                        register={register}
                        name={`weekGenerations.${weekIndex}.day`}
                      />
                      {weekIndex >= 1 && (
                        <button
                          type="button"
                          className="w-8 h-[40px] mt-6 box-border rounded border border-[#E4E6F2] text-[#60657B]"
                          onClick={() => WeekGenerationsRemove(weekIndex)}>
                          X
                        </button>
                      )}
                    </div>
                    {errors && (
                      <p className="mb-0 text-sm text-amber-800">
                        {errors?.weekGenerations?.[weekIndex]?.day?.message}
                      </p>
                    )}
                    {weekIndex === 0 && (
                      <section>
                        <button className="text-[#2B2D38] opacity-50 text-base" type="button" onClick={onClickAddWeek}>
                          + Add New Week
                        </button>
                      </section>
                    )}
                  </div>
                </div>
              );
            })}
          <div className="grid mt-5 md:grid-cols-4 sm:gap-6 gap-2">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id={`is_both`}
                  aria-describedby="comments-description"
                  name={`is_both`}
                  {...register(`is_both`)}
                  onChange={e => {
                    setIs_both(e.target.checked);
                    if (salary == 2) {
                      setSalary(1);
                      setValue('employee_salary_type', 1);
                    }
                  }}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor={`is_both`} className="font-medium text-gray-900">
                  Dual Status Employee
                </label>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id={`mileageReimbursement`}
                  aria-describedby="comments-description"
                  name={`mileageReimbursement`}
                  {...register(`mileageReimbursement`)}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor={`mileageReimbursement`} className="font-medium text-gray-900">
                  Eligible for mileage reimbursement
                </label>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id={`eligibleOvertimeForRequests`}
                  aria-describedby="comments-description"
                  name={`eligibleOvertimeForRequests`}
                  {...register(`eligibleOvertimeForRequests`)}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor={`eligibleOvertimeForRequests`} className="font-medium text-gray-900">
                  Eligible Overtime For Requests
                </label>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id={`companyBenefitsPlan`}
                  aria-describedby="comments-description"
                  name={`companyBenefitsPlan`}
                  {...register(`companyBenefitsPlan`)}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor={`companyBenefitsPlan`} className="font-medium text-gray-900">
                  Eligible for company benefits
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-5 mb-5">
            <section
              className={`border rounded  text-center px-3 py-1 min-w-[204px] max-w-[204px] ${
                ContractsEmployeeFields.length !== 0 && `bg-[#A6ADC9]`
              } `}>
              <button
                className={`text-[#4885ED] text-sm font-normal ${
                  ContractsEmployeeFields.length !== 0 && `text-[white]`
                }`}
                type="button"
                disabled={ContractsEmployeeFields.length !== 0}
                onClick={onClickAddEmergencyContact}>
                Add Emergency Contact
              </button>
            </section>
          </div>
        </div>
        <div className="w-full bg-white p-4 mt-6 rounded-md">
          <ContractsEmployeeField
            ContractsEmployeeFields={ContractsEmployeeFields}
            ContractsEmployeeRemove={ContractsEmployeeRemove}
            control={control}
            errors={errors}
            register={register}
            watch={watch}
            setValue={setValue}
            statesData={statesData}
          />
          <EmploymentsField
            EmploymentsFields={EmploymentsFields}
            getValues={getValues}
            today={today}
            control={control}
            register={register}
            setValue={setValue}
            employeeData={employeeData}
            errors={errors}
            watch={watch}
            EmploymentsAppend={EmploymentsAppend}
            EmploymentsRemove={EmploymentsRemove}
            salary={salary}
          />
          <DropZone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isFocused={isFocused}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
            state={stateSingature}
          />
          <div>
            <CancleAdd buttonText={'Save'} onClickCancle={onClickCancle} disabled={isLoading} />
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}
