export const EditEmployeeDefaultValue = employeeData => {
  return {
    state_id: employeeData?.state_id,
    city_id: employeeData?.city_id,
    contacts: employeeData?.contacts,
    status: employeeData?.status,
    gender: employeeData?.gender,
    employee_salary_type: employeeData?.employee_salary_type,
    alt_phone: employeeData?.alt_phone,
    cell_phone: employeeData?.cell_phone,
    weeks: employeeData?.weeks,
    hours: employeeData?.hours,
    companyBenefitsPlan: employeeData?.companyBenefitsPlan,
    mileageReimbursement: employeeData?.mileageReimbursement,
    eligibleOvertimeForRequests: employeeData?.eligibleOvertimeForRequests,
    employee_type: 0,
    is_both: employeeData?.is_both,
    assignment_details: employeeData?.assignment_details,
    positions: {
      value: employeeData?.positions?.value || employeeData?.positions?.label,
      label: employeeData?.positions?.label,
    },
    type: employeeData?.type || 1,
    base_salaries:
      employeeData?.base_salaries?.length > 0
        ? employeeData?.base_salaries?.map(e => ({
            id: e?.id,
            hourly_rate: e?.monthly_salary,
            start_date: e?.start_date,
          }))
        : [{}],
    // employee_image: employeeData?.employee_image,
    // eligibleRush: employeeData?.eligibleRush,
    // eligibleOvertime: employeeData?.eligibleOvertime,
    dob: employeeData?.dob,
    start_calculating_logs_date: employeeData?.start_calculating_logs_date || '',
    driverLicense: employeeData?.driverLicense,
    email: employeeData?.email,
    verified: employeeData?.verified,
    email_personal: employeeData?.email_personal,
    employments: employeeData?.employments?.map(e => ({
      id: e?.id,
      assignment_details: e?.assignment_details,
      employee_id: e?.employee_id,
      position_id: e?.position_id,
      school_id: e?.school_id,
      currency:
        e?.currency?.length > 0
          ? e?.currency?.map(item => ({
              id: item?.id,
              start_date: item?.start_date,
              hourly_rate: item?.hourly_rate,
            }))
          : [{}],
      service_id: e?.service_id,
      type: e?.type,
      status: e?.status == 1 ? true : false,
      eligibleRush: e?.eligibleRush == 1 ? true : false,
      eligibleOvertime: e?.eligibleOvertime == 1 ? true : false,
    })),
    id: employeeData?.id,
    lastName: employeeData?.lastName,
    middleName: employeeData?.middleName,
    name: employeeData?.name,
    socialSecurityNumber: employeeData?.socialSecurityNumber,
    street: employeeData?.street,
    zipcode: employeeData?.zipcode,
    weekGenerations:
      employeeData?.weekGenerations?.length > 0
        ? employeeData?.weekGenerations?.map(weekItem => ({
            id: weekItem?.id || 0,
            day: weekItem?.day,
          }))
        : [
            {
              id: 0,
              day: '',
            },
          ],
  };
};

export default EditEmployeeDefaultValue;
