import { useParams } from 'react-router-dom';

export default function ServiceLogCard({ navigateEdit, data, unbillable, Admin, navigateEditRequest }) {
  console.log(data, 'data');

  const params = useParams();
  return (
    <div className="flex flex-col gap-3 pr-2 max-h-[648px] overflow-y-auto">
      {data?.map((e, i) => (
        <div key={i} className="bg-[#C7DCFF] rounded-md p-4 flex gap-6">
          <div className="flex flex-col justify-center gap-2">
            <div className="flex flex-col justify-center">
              <img src="/bacakanchakan.svg" alt="img" />
            </div>
            {Admin ? (
              <button
                className="flex flex-col justify-center cursor-pointer"
                disabled={
                  data?.[0]?.status == 1 || e?.request_status === 'Approved' || e?.request_status === 'Completed'
                }
                // disabled={data?.[0]?.status == 1}
                onClick={() =>
                  e?.is_request_log ? navigateEditRequest(e?.id, params?.id2) : navigateEdit(e?.id, params?.id2)
                }>
                {/* {data?.[0]?.status == 1  ? ( */}
                {data?.[0]?.status == 1 || e?.request_status === 'Approved' || e?.request_status === 'Completed' ? (
                  <img src="/editServiceLogIcon.svg" alt="img" />
                ) : (
                  <img src="/editServiceLogIconActive.svg" alt="img" />
                )}
              </button>
            ) : (
              <button
                className="flex flex-col justify-center cursor-pointer"
                disabled={
                  data?.[0]?.status == 1 ||
                  e?.new_case_stasus == 0 ||
                  e?.request_status === 'Approved' ||
                  e?.request_status === 'Completed'
                }
                onClick={() => (e?.is_request_log ? navigateEditRequest(e?.id) : navigateEdit(e?.id))}>
                {/* <img src="/editServiceLogIcon.svg" alt="img" /> */}
                {data?.[0]?.status == 1 ||
                e?.new_case_stasus == 0 ||
                e?.request_status === 'Approved' ||
                e?.request_status === 'Completed' ? (
                  <img src="/editServiceLogIcon.svg" alt="img" />
                ) : (
                  <img src="/editServiceLogIconActive.svg" alt="img" className="w-[28px] h-[28px]" />
                )}
              </button>
            )}
          </div>
          <div>
            <p className="text-base font-extrabold flex flex-col">
              <span>{e?.day}</span>
              <span>{e?.start + ' - ' + e?.end}</span>
            </p>
            <p className="text-base font-normal">{e?.title}</p>
            <p className={`text-sm font-normal text-[#60657B] ${unbillable && 'w-[240px] overflow-hidden'}`}>
              {unbillable ? e?.activity : e?.positions}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
