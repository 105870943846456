import { useMemo } from 'react';

export default function MultiDropZone({
  getRootProps,
  getInputProps,
  isFocused,
  isDragAccept,
  isDragReject,
  state,
  text = 'Click to Select Files',
  handleRemoveFile,
}) {
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const focusedStyle = { borderColor: '#2196f3' };
  const acceptStyle = { borderColor: '#00e676' };
  const rejectStyle = { borderColor: '#ff1744' };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  // Image file extensions that should have a different display format
  const imageExtensions = ['png', 'jpeg', 'jpg', 'svg'];

  // File icons mapping based on extension (default icons for non-image files)
  const fileIcons = {
    pdf: '/PDFIcon.png',
    xls: '/ExcelPdf.svg',
    xlsx: '/ExcelPdf.svg',
    csv: '/ExcelPdf.svg',
    doc: '/WordPdf.svg',
    docx: '/WordPdf.svg',
    png: '/PngIcon.svg',
    jpeg: '/JpgIcon.svg',
    jpg: '/JpgIcon.svg',
  };

  return (
    <div className="flex flex-col gap-4 mt-4">
      {/* Display uploaded files */}
      {handleRemoveFile
        ? state?.cdnUrl?.length > 0 && (
            <div className="grid grid-cols-10 gap-6">
              {state.cdnUrl.map((file, index) => {
                const ext = file?.url?.split('.')?.pop()?.toLowerCase();
                return (
                  <div key={index} className="relative">
                    <button
                      type="button"
                      className="absolute top-0 right-0 bg-red-500 text-white text-xs px-1 rounded-full"
                      onClick={() => handleRemoveFile(file?.id)}>
                      ✕
                    </button>
                    {imageExtensions?.includes(ext) ? (
                      <a href={file?.url} download={file?.name || `image.${ext}`} rel="noreferrer" target="_blank">
                        <img alt="file" src={fileIcons[ext] || '/default-file.svg'} className="w-16 h-16" />
                        <p className="text-xs w-full">{file?.name}</p>
                      </a>
                    ) : (
                      <a href={file?.url} download={file?.name} target="_blank" rel="noreferrer">
                        <img alt="file" src={fileIcons[ext] || '/default-file.svg'} className="w-16 h-16" />
                        <p className="text-xs w-full">{file?.name}</p>
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          )
        : state?.cdnUrl?.length > 0 && (
            <div className="grid grid-cols-10 gap-4">
              {state.cdnUrl.map((file, index) => {
                const ext = file?.url?.split('.')?.pop()?.toLowerCase(); // Get file extension
                // If the file is an image, display it differently
                if (imageExtensions?.includes(ext)) {
                  return (
                    <button key={index} type="button">
                     <a href={file?.url} download={file?.name} target="_blank" rel="noreferrer">
                        <img alt="file" src={fileIcons[ext] || '/default-file.svg'} className="w-16 h-16" />
                        <p className="text-xs w-full">{file?.name}</p>
                      </a>
                    </button>
                  );
                }

                // Otherwise, use the default display format
                return (
                  <button key={index} type="button">
                    <a
                      href={file?.url}
                      download={file?.name}
                      target="_blank"
                      className="flex items-center flex-col gap-2 p-2 rounded-md"
                      rel="noreferrer">
                      <img alt="file" src={fileIcons[ext] || '/default-file.svg'} className="w-16 h-16" />
                      <p className="text-xs">{file?.name}</p>
                    </a>
                  </button>
                );
              })}
            </div>
          )}

      {getInputProps && (
        <section className="container">
          <div {...getRootProps({ style })}>
            <input
              {...getInputProps()}
              aria-describedby="file_input_help"
              id="file_input"
              type="file"
              style={{ display: 'block' }}
            />
            <p>{text}</p>
          </div>
        </section>
      )}
    </div>
  );
}
