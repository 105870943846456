import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import Pagination from '../../components/Pagination/Pagination';
import TableClients from '../../components/DataTable/TableClients';
import requestHttp from '../../http/requestHttp/requestHttp';
import { columnsMyRequestsClient } from '././helpers/MyRequestsClientUtils';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoListModal from '../../components/Modal/InfoListModal';

export default function MyRequestsClient() {
  const [myRequestList, setMyRequestList] = useState([]);
  const [myRequestListPage, setMyRequestListPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [newLoad, setNewLoad] = useState(false);
  const [requestId, setRequestId] = useState();
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [List, setList] = useState();
  const navigate = useNavigate();

  const fetchRequestList = async (pageId = '') => {
    setLoading(true);
    try {
      const response = await requestHttp.getMyRequestsClient(pageId);
      setMyRequestList(response?.data?.data || []);
      setMyRequestListPage(response?.data || {});
    } catch (err) {
      console.error('Error fetching company forms:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequestList();
  }, [newLoad]);

  const handlePaginationClick = id => {
    fetchRequestList(id);
  };
  
  const onClickEdit = (id, isOpen) => {
    isOpen && navigate(`/Client/EditForm/${id}`);
  };

  const onClickView = id => {
    navigate(`/Client/ViewForm/${id}`);
  };

  const onClickRemove = (id, isOpen) => {
    isOpen && setIsOpen(true);
    isOpen && setRequestId(id);
  };

  const afterRequest = title => {
    notifySuccess(title);
  };

  const onClickYes = id => {
    requestHttp
      .deleteRequestForClient(id)
      .then(e => e.status === 200 && setNewLoad(!newLoad))
      .then(() => afterRequest('Request successfully Deleted'));
    setIsOpen(false);
  };

  const onClickEmployeeList = list => {
    if (list?.length > 1) {
      setIsOpenEmployee(true);
      setList(list);
    }
  };

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <InfoListModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        List={List}
        title={'For which employees is this Request'}
      />
      <CompanyFormModal
        open={isOpen}
        setOpen={setIsOpen}
        onClickYes={onClickYes}
        companyFormId={requestId}
        text="Are you sure you want to delete this Request ?"
      />
      <TitleBlok textItem="My Requests" className="text-base font-semibold" src="/CompanyFormsIcon.svg" />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className={`w-full overflow-x-auto  ${loading && `animate-pulse`}`}>
          <div className="inline-block min-w-full">
            <TableClients
              tbody={myRequestList}
              columns={columnsMyRequestsClient(onClickEdit, onClickRemove, onClickEmployeeList, onClickView)}
              maxHeight="500px"
            />
          </div>
        </div>
        <Pagination onPaginationClick={handlePaginationClick} paginationData={myRequestListPage?.links} />
      </div>
      <ToastContainer />
    </div>
  );
}
